import React, {useEffect, useState} from "react";
import MarketplaceService from "../../../../service/marketplace/marketplace.service";
import {IBackofficeOffer} from "../../../../service/marketplace/model/offer.model";
import {BackofficeOffer} from "./component/backoffice-offer";
import {NotificationService} from "../../../../service/notification/notification.service";
import {NotificationType} from "../../../../service/notification/model/notification-type.enum";

export function Campaigns() {

    const [backofficeOffers, setBackofficeOffers] = useState<IBackofficeOffer[]>([])

    useEffect(() => {
        fetchOffers()
    }, []);

    async function fetchOffers() {
        try {
            setBackofficeOffers(await MarketplaceService.getBackofficeOffers())
        } catch (error) {
            console.log(error)
        }
    }


    async function deleteOffer(ref: string) {
        try {
            await MarketplaceService.deleteBackofficeOffer(ref)
            NotificationService.displayNotification({
                message: 'Offer deleted!', type: NotificationType.success
            })
            setBackofficeOffers(prevOffers => prevOffers.filter(backofficeOffer => backofficeOffer.offer.ref !== ref));
        } catch (error) {
            NotificationService.displayNotification({
                message: 'Could not delete Offer!', type: NotificationType.failure
            })
        }
    }

    async function sendOfferAsMail(ref: string) {
        try {
            NotificationService.displayNotification({
                message: 'Offer sent!', type: NotificationType.success
            })

            const index = backofficeOffers.findIndex(offer => offer.offer.ref === ref)
            backofficeOffers[index].sentAsMail = true
            setBackofficeOffers([...backofficeOffers])

        } catch (error) {
            NotificationService.displayNotification({
                message: 'Could not send Offer as Mail!', type: NotificationType.failure
            })
        }
    }

    return <section>
        {backofficeOffers?.map(offer => <BackofficeOffer backofficeOffer={offer} onDelete={deleteOffer} onSend={sendOfferAsMail}/> )}
    </section>
}